module.exports = {
  // onInitialClientRender: () => {
  onClientEntry: function() {
   // note: tried to drop in js provided by Heap Analytics here but got parse errors
  },
  onRouteUpdate: (cur, prev) => {
    // console.log(`tmp - onRouteUpdate from ${JSON.stringify(prev, null, 2)} to ${JSON.stringify(cur, null, 2)}`);
    if (cur.location.pathname === '/') {

    }
  }
};
